import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';

const CustomToggle = ({ label, checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggleChange = (event) => {
    const newValue = event.target.checked;
    setIsChecked(newValue);
    onChange(newValue);
  };

  return (
    <FormControlLabel
      control={<Switch checked={isChecked} onChange={handleToggleChange} color="primary" />}
      label={label}
    />
  );
};

export default CustomToggle;