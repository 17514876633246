import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  FormControlLabel,
  Switch,
  ToggleButton,
  Grid,
  CircularProgress,
  Button,
  TextField,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import ScrollToTop from '../components/ScrollToTop'; // Make sure to update the path if needed
import '../styles/global.css';
import { useTranslation } from 'react-i18next';

const PropertyCondition = () => {
  const { t } = useTranslation();
  const { propertyData, updatePropertyData } = useProperty();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [condition, setCondition] = useState(propertyData.condition || '');
  const [exteriorFinish, setExteriorFinish] = useState(propertyData.exterior_finish || '');
  const [exteriorJoinery, setExteriorJoinery] = useState(propertyData.exterior_joinery || '');
  const [energyCertificate, setEnergyCertificate] = useState(Boolean(propertyData.energy_certificate));
  const [constructionPeriod, setConstructionPeriod] = useState(propertyData.construction_period || '');
  const [yearOfConstruction, setYearOfConstruction] = useState(propertyData.year_of_construction || '');
  const [loading, setLoading] = useState(false);
  const [debug, setDebug] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState('');
  const [images, setImages] = useState([]);
  const [imageUploadComplete, setImageUploadComplete] = useState(true); // Default to true to allow proceeding without images
  const [dialogOpen, setDialogOpen] = useState(false); // New state to manage the fallback dialog
  const [processingMessage, setProcessingMessage] = useState(''); // New state for processing message

  const transactionType = propertyData.transaction_type;
  const propertyType = propertyData.property_type;

  const adminEmails = process.env.REACT_APP_ADMIN_EMAILS ? process.env.REACT_APP_ADMIN_EMAILS.split(',') : [];

  useEffect(() => {
    if (transactionType === 'For Sale') {
      if (constructionPeriod === 'Bare Shell') {
        setCondition('Bare Shell');
      } else if (constructionPeriod === 'Under Construction' || constructionPeriod === 'Newly Built, Never Lived In') {
        setCondition('Standard');
      }
    } else if (transactionType === 'For Rent' && constructionPeriod === 'Newly Built, Never Lived In') {
      setCondition('Standard');
    }
  }, [constructionPeriod, transactionType]);

  const handleImageUpload = async (event) => {
    const files = event.target.files;
    const newImages = Array.from(files);
    setImages((prevImages) => [...prevImages, ...newImages]);
    setProcessingMessage(t('propertyCondition.processingImages'));
    await processImages([...images, ...newImages]);
  };

  const handleImageRemove = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  const processImages = async (imageList) => {
    const formData = new FormData();
    imageList.forEach((image, index) => {
      formData.append('images', image);
    });

    setLoading(true);
    setImageUploadComplete(false); // Reset upload complete status

    const timeout = setTimeout(() => {
      setLoading(false);
      setDialogOpen(true); // Open the fallback dialog if processing takes too long
    }, 240000); // Set timeout to 4 minute (240000 ms)

    try {
      const response = await axios.post('https://imageapi.revaluer.app/property_image_analysis', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      clearTimeout(timeout); // Clear timeout if the API call is successful
      setApiResponse(response.data);
      updatePropertyData(response.data.summary); // Store the API response summary in context
      setImageUploadComplete(true); // Mark upload as complete
    } catch (error) {
      console.error('Error uploading images:', error);
      clearTimeout(timeout); // Clear timeout if an error occurs
      setDialogOpen(true); // Open the fallback dialog on error
    }
    setLoading(false);
    setProcessingMessage(''); // Clear processing message
  };

  const handleDebugToggle = () => setDebug(!debug);

  const handleNextClick = () => {
    const currentYear = new Date().getFullYear();
    const maxYear = currentYear + 3;

    // Remove the validation check for yearOfConstruction
    // if (yearOfConstruction < 1700 || yearOfConstruction > maxYear) {
    //   setError(t('propertyCondition.yearError', { maxYear }));
    //   return;
    // }

    updatePropertyData({
      condition: condition || null,
      exterior_finish: exteriorFinish || null,
      exterior_joinery: exteriorJoinery || null,
      energy_certificate: energyCertificate, // Ensure this is set for both House and Apartment
      construction_period: constructionPeriod || null,
      year_of_construction: yearOfConstruction || null, // Set to null if not provided
    });

    setError('');
    navigate('/furniture-equipment');
  };

  const handleChange = (setter, field) => (value) => {
    setter(value);
    updatePropertyData({ [field]: value });
  };

  const handleDialogClose = (proceed) => {
    setDialogOpen(false);
    if (proceed) {
      setImageUploadComplete(true); // Allow proceeding without image processing
    }
  };

  return (
    <Container maxWidth="md" className="container">
      <ScrollToTop /> {/* Scroll to top on component load */}
      <Button onClick={() => navigate(-1)} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>{t('propertyCondition.back')}</Button>
      <Typography variant="h5" component="h2" gutterBottom textAlign="center">
        {t('propertyCondition.title')}
      </Typography>
      <Box mt={4}>
        <Typography variant="subtitle1">{t('propertyCondition.constructionPeriod')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {(transactionType === 'For Rent' ? ['Lived In', 'Newly Built, Never Lived In'] : ['Lived In', 'Newly Built, Never Lived In', 'Bare Shell', 'Under Construction']).map((period) => (
            <Grid item key={period} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={period}
                selected={constructionPeriod === period}
                className={`toggle-button ${constructionPeriod === period ? 'selected' : ''}`}
                onClick={() => handleChange(setConstructionPeriod, 'construction_period')(period)}
                sx={{ width: '100%' }}
              >
                {t(`propertyCondition.constructionPeriodOptions.${period}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={4}>
        <Typography variant="subtitle1">
          {constructionPeriod === 'Under Construction' ? t('propertyCondition.expectedYear') : t('propertyCondition.yearOfConstruction')}
        </Typography>
        <TextField
          fullWidth
          type="number"
          inputProps={{ min: 1700, max: new Date().getFullYear() + 3, style: { textAlign: 'center' } }}
          value={yearOfConstruction}
          onChange={(e) => handleChange(setYearOfConstruction, 'year_of_construction')(e.target.value)}
          variant="outlined"
          sx={{ maxWidth: '120px', margin: '0 auto' }} // Set max width for the input box and center it
        />
      </Box>
      {error && (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      {(constructionPeriod === 'Lived In' || (transactionType === 'For Sale' && constructionPeriod !== 'Bare Shell' && constructionPeriod !== 'Under Construction' && constructionPeriod !== 'Newly Built, Never Lived In')) && (
        <Box mt={4}>
          <Typography variant="subtitle1">{t('propertyCondition.condition')}</Typography>
          <Grid container spacing={1} justifyContent="center">
            {['Standard', 'Renovation Needed', 'Completely Renovated', 'Bare Shell'].map((cond) => (
              <Grid item key={cond} sx={{ mr: 1, mb: 1 }}>
                <ToggleButton
                  value={cond}
                  selected={condition === cond}
                  className={`toggle-button ${condition === cond ? 'selected' : ''}`}
                  onClick={() => handleChange(setCondition, 'condition')(cond)}
                  sx={{ width: '100%' }}
                >
                  {t(`propertyCondition.conditionOptions.${cond}`)}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Box mt={4}>
        <Typography variant="subtitle1">{t('propertyCondition.exteriorFinish')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {['Standard', 'Poor Condition', 'Modern', 'Classic Style', 'Protected Structure', 'Unfinished'].map((finish) => (
            <Grid item key={finish} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={finish}
                selected={exteriorFinish === finish}
                className={`toggle-button ${exteriorFinish === finish ? 'selected' : ''}`}
                onClick={() => handleChange(setExteriorFinish, 'exterior_finish')(finish)}
                sx={{ width: '100%' }}
              >
                {t(`propertyCondition.exteriorFinishOptions.${finish}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={4}>
        <Typography variant="subtitle1">{t('propertyCondition.exteriorJoinery')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {['Wood', 'PVC', 'Aluminum', 'Fiberglass'].map((joinery) => (
            <Grid item key={joinery} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={joinery}
                selected={exteriorJoinery === joinery}
                className={`toggle-button ${exteriorJoinery === joinery ? 'selected' : ''}`}
                onClick={() => handleChange(setExteriorJoinery, 'exterior_joinery')(joinery)}
                sx={{ width: '100%' }}
              >
                {t(`propertyCondition.exteriorJoineryOptions.${joinery}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={4} textAlign="center">
        <FormControlLabel
          control={<Switch checked={energyCertificate} onChange={(e) => handleChange(setEnergyCertificate, 'energy_certificate')(e.target.checked)} color="primary" />}
          label={t('propertyCondition.energyCertificate')}
        />
      </Box>
      <Box mt={4} textAlign="center">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            sx={{ display: 'inline-block', marginBottom: '10px' }}
          >
            {t('propertyCondition.uploadImages')}
            <input
              type="file"
              hidden
              multiple
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>
          <Typography variant="body2" sx={{ marginBottom: '10px', maxWidth: '400px' }}>
            {t('propertyCondition.uploadInstructions')}
          </Typography>
          {processingMessage && (
            <Typography variant="body1" sx={{ marginBottom: '10px' }}>
              {processingMessage}
            </Typography>
          )}
          {loading && <CircularProgress sx={{ marginBottom: '10px' }} />}
        </Box>
      </Box>
      <Box mt={4} textAlign="center">
        {images.map((image, index) => (
          <Box key={index} sx={{ display: 'inline-block', position: 'relative', margin: '5px' }}>
            <img src={URL.createObjectURL(image)} alt={`uploaded-${index}`} style={{ width: '100px', height: '100px' }} />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleImageRemove(index)}
              sx={{ position: 'absolute', top: 0, right: 0 }}
            >
              X
            </Button>
          </Box>
        ))}
      </Box>
      {user && adminEmails.includes(user.email) && (
        <Box mt={4} textAlign="center">
          <FormControlLabel control={<Switch checked={debug} onChange={handleDebugToggle} color="primary" />} label={t('propertyCondition.toggleDebug')} />
        </Box>
      )}
      {debug && (
        <Box mt={2} textAlign="left" sx={{ wordWrap: 'break-word' }}>
          <Typography variant="h6">{t('propertyCondition.apiResponse')}</Typography>
          <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
        </Box>
      )}
      <Box mt={4} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextClick}
          disabled={loading} // Disable next button if loading
        >
          {t('propertyCondition.next')}
        </Button>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('propertyCondition.dialogTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('propertyCondition.dialogDescription')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">{t('propertyCondition.retry')}</Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>{t('propertyCondition.proceedWithoutImages')}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PropertyCondition;