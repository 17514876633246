import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  FormControlLabel,
  Switch,
  ToggleButton,
  Grid,
  TextField,
  Button,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import ScrollToTop from '../components/ScrollToTop'; // Make sure to update the path if needed
import '../styles/global.css';
import { useTranslation } from 'react-i18next';

const PropertyStructure = () => {
  const { t } = useTranslation();
  const { propertyData, updatePropertyData } = useProperty();
  const navigate = useNavigate();
  
  const [typeOfApartment, setTypeOfApartment] = useState('');
  const [houseType, setHouseType] = useState('');
  const [structure, setStructure] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [fullBaths, setFullBaths] = useState('');
  const [halfBaths, setHalfBaths] = useState('');
  const [balconiesAndTerraces, setBalconiesAndTerraces] = useState('');
  const [ceilingHeight, setCeilingHeight] = useState('');
  const [suitableForOfficeSpace, setSuitableForOfficeSpace] = useState(false);
  const [convertedIntoResidential, setConvertedIntoResidential] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showCustomBedrooms, setShowCustomBedrooms] = useState(false);
  const [showCustomFullBaths, setShowCustomFullBaths] = useState(false);
  const [showCustomHalfBaths, setShowCustomHalfBaths] = useState(false);
  const [showCustomBalconiesAndTerraces, setShowCustomBalconiesAndTerraces] = useState(false);
  const [customBedroomsValue, setCustomBedroomsValue] = useState('');
  const [customFullBathsValue, setCustomFullBathsValue] = useState('');
  const [customHalfBathsValue, setCustomHalfBathsValue] = useState('');
  const [customBalconiesAndTerracesValue, setCustomBalconiesAndTerracesValue] = useState('');

  useEffect(() => {
    setTypeOfApartment(propertyData.type_of_apartment || '');
    setHouseType(propertyData.house_type || '');
    setStructure(propertyData.structure || '');
    setBedrooms(propertyData.bedrooms || '');
    setFullBaths(propertyData.full_baths || '');
    setHalfBaths(propertyData.half_baths || '');
    setBalconiesAndTerraces(propertyData.balconies_and_terraces || '');
    setCeilingHeight(propertyData.ceiling_height || '');
    setSuitableForOfficeSpace(Boolean(propertyData.suitable_for_office_space));
    setConvertedIntoResidential(Boolean(propertyData.converted_into_residential));

    // Retrieve visibility state from local storage
    setShowCustomBedrooms(localStorage.getItem('showCustomBedrooms') === 'true');
    setShowCustomFullBaths(localStorage.getItem('showCustomFullBaths') === 'true');
    setShowCustomHalfBaths(localStorage.getItem('showCustomHalfBaths') === 'true');
    setShowCustomBalconiesAndTerraces(localStorage.getItem('showCustomBalconiesAndTerraces') === 'true');
  }, [propertyData]);

  const handleNextClick = () => {
    if (
      (bedrooms === '5+' && (propertyData.bedrooms < 5 || propertyData.bedrooms > 100)) ||
      (fullBaths === '5+' && (propertyData.full_baths < 5 || propertyData.full_baths > 100)) ||
      (halfBaths === '5+' && (propertyData.half_baths < 5 || propertyData.half_baths > 100)) ||
      (balconiesAndTerraces === '5+' && (propertyData.balconies_and_terraces < 5 || propertyData.balconies_and_terraces > 100))
    ) {
      setShowWarning(true);
      return;
    }

    updatePropertyData({
      type_of_apartment: typeOfApartment,
      house_type: houseType,
      structure: structure,
      bedrooms: propertyData.bedrooms,
      full_baths: propertyData.full_baths,
      half_baths: propertyData.half_baths,
      balconies_and_terraces: propertyData.balconies_and_terraces,
      ceiling_height: ceilingHeight,
      suitable_for_office_space: suitableForOfficeSpace,
      converted_into_residential: convertedIntoResidential,
    });
    navigate('/position-orientation-view');
  };

  const handleBedroomsChange = (bedroom) => {
    const bedroomValue = bedroom === 'Studio' ? '0' : bedroom;
    setBedrooms(bedroomValue);
    if (bedroomValue !== '5+') {
      updatePropertyData({ bedrooms: bedroomValue });
      setShowCustomBedrooms(false);
      localStorage.setItem('showCustomBedrooms', 'false');
    } else {
      setShowCustomBedrooms(true);
      localStorage.setItem('showCustomBedrooms', 'true');
    }
  };

  const handleFullBathsChange = (bath) => {
    const bathValue = bath === 'None' ? '0' : bath;
    setFullBaths(bathValue);
    if (bathValue !== '5+') {
      updatePropertyData({ full_baths: bathValue });
      setShowCustomFullBaths(false);
      localStorage.setItem('showCustomFullBaths', 'false');
    } else {
      setShowCustomFullBaths(true);
      localStorage.setItem('showCustomFullBaths', 'true');
    }
  };

  const handleHalfBathsChange = (bath) => {
    const bathValue = bath === 'None' ? '0' : bath;
    setHalfBaths(bathValue);
    if (bathValue !== '5+') {
      updatePropertyData({ half_baths: bathValue });
      setShowCustomHalfBaths(false);
      localStorage.setItem('showCustomHalfBaths', 'false');
    } else {
      setShowCustomHalfBaths(true);
      localStorage.setItem('showCustomHalfBaths', 'true');
    }
  };

  const handleBalconiesAndTerracesChange = (balcony) => {
    const balconyValue = balcony === 'None' ? '0' : balcony;
    setBalconiesAndTerraces(balconyValue);
    if (balconyValue !== '5+') {
      updatePropertyData({ balconies_and_terraces: balconyValue });
      setShowCustomBalconiesAndTerraces(false);
      localStorage.setItem('showCustomBalconiesAndTerraces', 'false');
    } else {
      setShowCustomBalconiesAndTerraces(true);
      localStorage.setItem('showCustomBalconiesAndTerraces', 'true');
    }
  };

  const handleCustomInputChange = (field, value) => {
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      updatePropertyData({ [field]: numericValue });
      if (numericValue >= 5 && numericValue <= 9) {
        setShowCustomBedrooms(false);
        setShowCustomFullBaths(false);
        setShowCustomHalfBaths(false);
        setShowCustomBalconiesAndTerraces(false);
        localStorage.setItem('showCustomBedrooms', 'false');
        localStorage.setItem('showCustomFullBaths', 'false');
        localStorage.setItem('showCustomHalfBaths', 'false');
        localStorage.setItem('showCustomBalconiesAndTerraces', 'false');
      } else if (numericValue >= 10) {
        setShowCustomBedrooms(false);
        setShowCustomFullBaths(false);
        setShowCustomHalfBaths(false);
        setShowCustomBalconiesAndTerraces(false);
        localStorage.setItem('showCustomBedrooms', 'false');
        localStorage.setItem('showCustomFullBaths', 'false');
        localStorage.setItem('showCustomHalfBaths', 'false');
        localStorage.setItem('showCustomBalconiesAndTerraces', 'false');
      }
    } else {
      updatePropertyData({ [field]: '' });
    }
  };

  const resetVisibilityState = () => {
    setShowCustomBedrooms(false);
    setShowCustomFullBaths(false);
    setShowCustomHalfBaths(false);
    setShowCustomBalconiesAndTerraces(false);
    localStorage.setItem('showCustomBedrooms', 'false');
    localStorage.setItem('showCustomFullBaths', 'false');
    localStorage.setItem('showCustomHalfBaths', 'false');
    localStorage.setItem('showCustomBalconiesAndTerraces', 'false');
  };

  // Call resetVisibilityState when resetting the data context
  useEffect(() => {
    resetVisibilityState();
  }, [propertyData]);

  return (
    <Container maxWidth="md" className="container">
      <ScrollToTop /> {/* Scroll to top on component load */}
      <Button onClick={() => navigate(-1)} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>{t('propertyStructure.back')}</Button>
      <Box className="mt-1">
        <Typography variant="h5">{t('propertyStructure.bedrooms')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {['Studio', '1', '2', '3', '4', '5+'].map((bedroom) => (
            <Grid item key={bedroom} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={bedroom}
                selected={bedrooms === (bedroom === 'Studio' ? '0' : bedroom) || (bedroom === '5+' && propertyData.bedrooms >= 5)}
                className={`toggle-button ${bedrooms === (bedroom === 'Studio' ? '0' : bedroom) || (bedroom === '5+' && propertyData.bedrooms >= 5) ? 'selected' : ''}`}
                onClick={() => handleBedroomsChange(bedroom)}
                sx={{ width: '100%' }}
              >
                {t(`propertyStructure.bedrooms.options.${bedroom.toLowerCase()}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
        {showCustomBedrooms && (
          <Box className="mt-4">
            <TextField
              label={t('propertyStructure.enterNumberOfBedrooms')}
              type="number"
              fullWidth
              value={customBedroomsValue}
              onChange={(e) => {
                const value = e.target.value;
                setCustomBedroomsValue(value);
                if (value.length === 1 && (value === '1' || value === '2')) {
                  return;
                }
                handleCustomInputChange('bedrooms', value);
              }}
              margin="normal"
            />
          </Box>
        )}
      </Box>
      <Box className="mt-4">
        <Typography variant="h5">{t('propertyStructure.fullBaths')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {['None', '1', '2', '3', '4', '5+'].map((bath) => (
            <Grid item key={bath} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={bath}
                selected={fullBaths === (bath === 'None' ? '0' : bath) || (bath === '5+' && propertyData.full_baths >= 5)}
                className={`toggle-button ${fullBaths === (bath === 'None' ? '0' : bath) || (bath === '5+' && propertyData.full_baths >= 5) ? 'selected' : ''}`}
                onClick={() => handleFullBathsChange(bath)}
                sx={{ width: '100%' }}
              >
                {t(`propertyStructure.fullBaths.options.${bath.toLowerCase()}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
        {showCustomFullBaths && (
          <Box className="mt-4">
            <TextField
              label={t('propertyStructure.enterNumberOfFullBaths')}
              type="number"
              fullWidth
              value={customFullBathsValue}
              onChange={(e) => {
                const value = e.target.value;
                setCustomFullBathsValue(value);
                if (value.length === 1 && (value === '1' || value === '2')) {
                  return;
                }
                handleCustomInputChange('full_baths', value);
              }}
              margin="normal"
            />
          </Box>
        )}
      </Box>
      <Box className="mt-4">
        <Typography variant="h5">{t('propertyStructure.halfBaths')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {['None', '1', '2', '3', '4', '5+'].map((bath) => (
            <Grid item key={bath} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={bath}
                selected={halfBaths === (bath === 'None' ? '0' : bath) || (bath === '5+' && propertyData.half_baths >= 5)}
                className={`toggle-button ${halfBaths === (bath === 'None' ? '0' : bath) || (bath === '5+' && propertyData.half_baths >= 5) ? 'selected' : ''}`}
                onClick={() => handleHalfBathsChange(bath)}
                sx={{ width: '100%' }}
              >
                {t(`propertyStructure.halfBaths.options.${bath.toLowerCase()}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
        {showCustomHalfBaths && (
          <Box className="mt-4">
            <TextField
              label={t('propertyStructure.enterNumberOfHalfBaths')}
              type="number"
              fullWidth
              value={customHalfBathsValue}
              onChange={(e) => {
                const value = e.target.value;
                setCustomHalfBathsValue(value);
                if (value.length === 1 && (value === '1' || value === '2')) {
                  return;
                }
                handleCustomInputChange('half_baths', value);
              }}
              margin="normal"
            />
          </Box>
        )}
      </Box>
      <Box className="mt-4">
        <Typography variant="h5">{t('propertyStructure.balconiesAndTerraces')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {['None', '1', '2', '3', '4', '5+'].map((balcony) => (
            <Grid item key={balcony} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={balcony}
                selected={balconiesAndTerraces === (balcony === 'None' ? '0' : balcony) || (balcony === '5+' && propertyData.balconies_and_terraces >= 5)}
                className={`toggle-button ${balconiesAndTerraces === (balcony === 'None' ? '0' : balcony) || (balcony === '5+' && propertyData.balconies_and_terraces >= 5) ? 'selected' : ''}`}
                onClick={() => handleBalconiesAndTerracesChange(balcony)}
                sx={{ width: '100%' }}
              >
                {t(`propertyStructure.balconiesAndTerraces.options.${balcony.toLowerCase()}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
        {showCustomBalconiesAndTerraces && (
          <Box className="mt-4">
            <TextField
              label={t('propertyStructure.enterNumberOfBalconiesAndTerraces')}
              type="number"
              fullWidth
              value={customBalconiesAndTerracesValue}
              onChange={(e) => {
                const value = e.target.value;
                setCustomBalconiesAndTerracesValue(value);
                if (value.length === 1 && (value === '1' || value === '2')) {
                  return;
                }
                handleCustomInputChange('balconies_and_terraces', value);
              }}
              margin="normal"
            />
          </Box>
        )}
      </Box>
      <Box className="mt-4">
        <Typography variant="h5">{t('propertyStructure.structure')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {propertyData.property_type === 'Apartment'
            ? ['Standard Layout', 'Salon Apartment', 'Open-Plan Apartment', 'Duplex', 'Penthouse', 'Loft Apartment', 'Maisonette', 'Garden Apartment', 'Rooftop Access', 'Subpar Layout', 'Large Terrace'].map((type) => (
                <Grid item key={type} sx={{ mr: 1, mb: 1 }}>
                  <ToggleButton
                    value={type}
                    selected={structure === type}
                    className={`toggle-button ${structure === type ? 'selected' : ''}`}
                    onClick={() => {
                      setStructure(type);
                      updatePropertyData({ structure: type });
                    }}
                    sx={{ width: '100%' }}
                  >
                    {t(`propertyStructure.structure.options.${type.toLowerCase().replace(/[- ]/g, '')}`)}
                  </ToggleButton>
                </Grid>
              ))
            : ['Single-Family Home', 'Multi-Family Home with Separate Apartments', 'House Vertical Division', 'House Horizontal Division', 'Prefabricated House'].map((type) => (
                <Grid item key={type} sx={{ mr: 1, mb: 1 }}>
                  <ToggleButton
                    value={type}
                    selected={structure === type}
                    className={`toggle-button ${structure === type ? 'selected' : ''}`}
                    onClick={() => {
                      setStructure(type);
                      updatePropertyData({ structure: type });
                    }}
                    sx={{ width: '100%' }}
                  >
                    {t(`propertyStructure.structure.options.${type.toLowerCase().replace(/ /g, '').replace(/-/g, '')}`)}
                  </ToggleButton>
                </Grid>
              ))}
        </Grid>
      </Box>
      {propertyData.property_type === 'Apartment' && (
        <Box className="mt-4">
          <Typography variant="h5">{t('propertyStructure.apartmentType')}</Typography>
          <Grid container spacing={1} justifyContent="center">
            {['Apartment in a Building', 'Apartment in a House', 'Apartment within a Hotel', 'A Single Room'].map((type) => (
              <Grid item key={type} sx={{ mr: 1, mb: 1 }}>
                <ToggleButton
                  value={type}
                  selected={typeOfApartment === type}
                  className={`toggle-button ${typeOfApartment === type ? 'selected' : ''}`}
                  onClick={() => {
                    setTypeOfApartment(type);
                    updatePropertyData({ type_of_apartment: type });
                  }}
                  sx={{ width: '100%' }}
                >
                  {t(`propertyStructure.apartmentType.options.${type.toLowerCase().replace(/ /g, '')}`)}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {propertyData.property_type === 'House' && (
        <Box className="mt-4">
          <Typography variant="h5">{t('propertyStructure.houseType')}</Typography>
          <Grid container spacing={1} justifyContent="center">
            {['House in Urban Area', 'Village House', 'House in Sparsely Settled Area (e.g. mountain)', 'Luxury Urban Villa', 'Vacation Home', 'Stilt House'].map((type) => (
              <Grid item key={type} sx={{ mr: 1, mb: 1 }}>
                <ToggleButton
                  value={type}
                  selected={houseType === type}
                  className={`toggle-button ${houseType === type ? 'selected' : ''}`}
                  onClick={() => {
                    setHouseType(type);
                    updatePropertyData({ house_type: type });
                  }}
                  sx={{ width: '100%' }}
                >
                  {t(`propertyStructure.houseType.options.${type.toLowerCase().replace(/ /g, '')}`)}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Box className="mt-4">
        <Typography variant="h5">{t('propertyStructure.ceilingHeight.title')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {['Standard Ceiling Height', 'Low Ceiling (Below 2.5m)', 'High Ceiling (Above 3m)', 'Very High Ceiling (Above 3.7m)', 'Sloped Ceiling'].map((height) => (
            <Grid item key={height} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={height}
                selected={ceilingHeight === height}
                className={`toggle-button ${ceilingHeight === height ? 'selected' : ''}`}
                onClick={() => {
                  setCeilingHeight(height);
                  updatePropertyData({ ceiling_height: height });
                }}
                sx={{ width: '100%' }}
              >
                {t(`propertyStructure.ceilingHeight.options.${height.toLowerCase().replace(/ /g, '')}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box className="mt-4">
        <FormControlLabel
          control={<Switch checked={suitableForOfficeSpace} onChange={(e) => {
            setSuitableForOfficeSpace(e.target.checked);
            updatePropertyData({ suitable_for_office_space: e.target.checked });
          }} color="primary" />}
          label={t('propertyStructure.suitableForBusiness')}
        />
      </Box>
      <Box className="mt-4">
        <FormControlLabel
          control={<Switch checked={convertedIntoResidential} onChange={(e) => {
            setConvertedIntoResidential(e.target.checked);
            updatePropertyData({ converted_into_residential: e.target.checked });
          }} color="primary" />}
          label={t('propertyStructure.convertedIntoResidential')}
        />
      </Box>
      {showWarning && (
        <Box className="mt-4">
          <Alert severity="error">{t('propertyStructure.customValuesWarning')}</Alert>
        </Box>
      )}
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleNextClick}>
          {t('propertyStructure.next')}
        </Button>
      </Box>
    </Container>
  );
};

export default PropertyStructure;